@import "../../Assets/css/mq";

.main-nav {
  display: none;

  @include mq($from: tablet) {
    display: block;
  }

  .list {
    margin: 0;
  }

  .item {
    display: inline-block;
    margin-left: 30px;

    .link {
      font-family: var(--font-family-sans-serif-semibold);
      color: var(--color-primary);
      text-decoration: none;

      transition: color var(--default-transition-params);

      &:hover,
      &.active {
        color: var(--color-green);
      }
    }

    &:first-child {
      margin-left: 0;
    }
  }
}

.hamburger {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 40px;
  height: 31px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  background: none;
  border: none;

  @include mq($from: tablet) {
    display: none;
  }

  &:hover {
    cursor: pointer;

    .line {
      transform: rotate(0deg);
      background-color: var(--color-green-darker);
      transform-origin: center;
      opacity: 1;
    }
  }
  .line {
    display: block;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background-color: var(--color-green);
    transition: all 0.2s ease;

    &:last-child {
      margin-bottom: 0;
    }
  }
  &.opened {
    .line {
      &:first-child {
        transform: rotate(-45deg) translate(-10px, 10px);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:last-child {
        transform: rotate(45deg) translate(-10px, -10px);
      }
    }
  }
}

.main-nav-mobile {
  display: none;

  &.opened {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100vw;

    margin-top: var(--main-header-height);

    display: flex;
    flex-direction: column;

    z-index: 2;

    background-color: var(--color-white);

    @include mq($from: tablet) {
      display: none;
    }
  }

  .logo-link {
    display: flex;
    padding-left: 20px;
    max-width: 210px;

    .logo {
      max-width: 210px;
      height: auto;
    }
  }

  .list {
    margin: 2vh 0 0 0;
    padding: 0;
  }

  .item {
    list-style: none;
    margin: 0;

    .link {
      display: block;
      width: 100%;
      padding: 16px 10px 16px 20px;

      font-family: var(--font-family-sans-serif-semibold);
      font-size: 28px;
      color: var(--color-primary);
      text-decoration: none;
      letter-spacing: 5px;

      transition: color var(--default-transition-params);

      &:hover,
      &.active {
        color: var(--color-green);
      }
    }

    &:first-child {
      margin-left: 0;
    }
  }
}

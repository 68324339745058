.page-sub-header-container {
    border-bottom: 2px solid var(--color-green);
    margin-bottom: 50px;

    .title {
        margin: 0 0 8px;
        font-size: 24px;
    }

    .intro {
        margin-bottom: 50px;
    }
}

.map-container {
    position: relative;
    width: 100%;
    height: 50vh;

    .btn-itineraire {
        position: absolute;
        top: 20px;
        left: 20px;

        z-index: 1;
    }
}

.contact-info-container {
    border-bottom: 2px solid var(--color-green);
    margin-bottom: 50px;
    padding-bottom: 50px;

    .contact-info {
        margin-bottom: 30px;
    }

    .contact-row {
        margin-top: 0;
        margin-bottom: 4px;
    }

    .contact-value {
        display: inline-flex;
        align-items: center;
        font-family: var(--font-family-sans-serif-semibold);
        color: var(--color-green);
    }

    .icon-social{
        margin-right: 10px;
        fill: var(--color-green);
        width: 20px;
    }
    

    .heures-ouverture {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            display: flex;
            justify-content: space-between;
            max-width: 400px;
            margin-bottom: 10px;

            .heure {
                font-family: var(--font-family-sans-serif-semibold);
            }
        }
    }
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 20, 2021 */
@font-face {
    font-family: 'acrombold';
    src: url('../../Assets/fonts/acrom/the_northern_block_ltd_-_acrom_bold-webfont.woff2') format('woff2'),
        url('../../Assets/fonts/acrom/the_northern_block_ltd_-_acrom_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'acromextrabold';
    src: url('../../Assets/fonts/acrom/the_northern_block_ltd_-_acrom_extrabold-webfont.woff2') format('woff2'),
        url('../../Assets/fonts/acrom/the_northern_block_ltd_-_acrom_extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'acromlight';
    src: url('../../Assets/fonts/acrom/the_northern_block_ltd_-_acrom_light-webfont.woff2') format('woff2'),
        url('../../Assets/fonts/acrom/the_northern_block_ltd_-_acrom_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'acrommedium';
    src: url('../../Assets/fonts/acrom/the_northern_block_ltd_-_acrom_medium-webfont.woff2') format('woff2'),
        url('../../Assets/fonts/acrom/the_northern_block_ltd_-_acrom_medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'acromthin';
    src: url('../../Assets/fonts/acrom/the_northern_block_ltd_-_acrom_thin-webfont.woff2') format('woff2'),
        url('../../Assets/fonts/acrom/the_northern_block_ltd_-_acrom_thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'acromregular';
    src: url('../../Assets/fonts/acrom/the_northern_block_ltd_-_acrom-webfont.woff2') format('woff2'),
        url('../../Assets/fonts/acrom/the_northern_block_ltd_-_acrom-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@import "../Assets/css/mq";

.home-container {
  .home-nav {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .home-nav-list {
      list-style: none;
      margin: 0 0 5vh;
      padding: 0;
      max-width: 90vw;
      text-align: center;
    }

    .item {
      margin-bottom: 4vh;

      &:last-child {
        margin: 0;
      }
    }

    .link {
      display: inline-flex;
      flex-direction: column;
      font-family: var(--font-family-sans-serif-semibold);
      text-align: center;
      text-decoration: none;
      color: var(--color-primary);

      .title {
        display: inline-block;
        font-size: 30px;
        text-transform: uppercase;
        line-height: 120%;
        letter-spacing: 5px;
        margin-bottom: 10px;

        @include mq($from: tablet) {
          font-size: 7vh;
          line-height: 120%;
          letter-spacing: 10px;
        }
      }

      .short-desc {
        display: inline-block;
        font-family: var(--font-family-serif);
        font-size: 18px;

        transform: translateY(-10px);

        @include mq($from: tablet) {
          font-size: 21px;
        }
      }

      &:hover {
        .title {
          color: var(--color-green);
          transition: color var(--default-transition-params);
        }
      }
    }
  }
}

@import "../../Assets/css/mq";

.main-header {
  padding: 14px 0;
  border-bottom: 1px solid var(--color-green);
  position: sticky;
  top: 0;
  background-color: var(--color-white);
  z-index: 1;

  @include mq($from: tablet) {
    position: relative;
    border-bottom: none;
    padding: 20px 0;
    background-color: inherit;
  }
}

.main-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  .logo-link {
    z-index: 3;
  }
  .logo {
    display: flex;
    max-width: 210px;
    width: 23vw;
    height: auto;
    min-width: 170px;
  }
}

.main-nav {
  .list {
    margin: 0;
  }

  .item {
    display: inline-block;
    margin-left: 2vw;

    .link {
      font-family: var(--font-family-sans-serif-semibold);
      color: var(--color-primary);
      text-decoration: none;

      transition: color var(--default-transition-params);

      &:hover,
      &.active {
        color: var(--color-green);
      }
    }

    &:first-child {
      margin-left: 0;
    }
  }
}

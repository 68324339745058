@import "../../Assets/css/mq";

.main-footer {
  position: sticky;
  bottom: 0;

  padding: 10px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  background-color: rgba(255, 255, 255, 0.85);
  border-top: 1px solid var(--color-green);

  @include mq($from: tablet) {
    background-color: initial;
    border: none;
  }

  .main-footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
  }

  .list-social-media {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .item {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  .icon-social {
    width: 30px;
    fill: var(--color-green);
    color: var(--color-green);
  }
}

@import "./mq";

.App {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: var(--main-header-height) 1fr auto;
  grid-template-areas:
    "."
    "."
    ".";

  gap: 0px 0px;

  min-height: 100%;
  height: 100%;
}

.main-container {
  margin: 0 auto;
  padding: 0 var(--grid-col-gap);
  width: calc(100% - (calc(var(--grid-col-gap) * 2)));
  max-width: calc(var(--max-width) - (calc(var(--grid-col-gap) * 2)));

  @include mq($from: tablet) {
    grid-template-columns: 1fr 2fr 1fr;
  }
}

.page-content {
  display: grid;
  grid-template-columns: 100%;
  column-gap: var(--grid-col-gap);

  .col-center {
    max-width: 600px;
  }

  @include mq($from: tablet) {
    grid-template-columns: 1fr 2fr 1fr;
  }
}

@import './mq';

:root {
    --color-dark-purple: #252238;
    --color-green: #0ED19C;
    --color-green-darker: #148c6c;
    --color-green-pale: #ADE1D3;
    --color-pink: #F95C73;
    --color-pink-darker: #c23e52;
    --color-white: #FFFFFF;
    --color-primary: var(--color-dark-purple);

    --font-family-sans-serif-light: 'acromlight';
    --font-family-sans-serif-regular: 'acromregular';
    --font-family-sans-serif-bold: 'acrombold';
    --font-family-sans-serif-semibold: 'acrommedium';
    --font-family-serif: 'EB Garamond';

    --max-width: 1440px;
    --grid-col-gap-mobile: 20px;
    --grid-col-gap: 20px;

    --main-header-height: 75px;

    --default-transition-params: 0.2s ease;

    @include mq($from: tablet) {
        --grid-col-gap: 40px;
    }
}

@import "../Assets/css/mq";

.coup-de-coeurs {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 20px;
    row-gap: 50px;
    padding-bottom: 50px;
    margin-bottom: 50px;
    border-bottom: 2px solid var(--color-green);

    @include mq($from: tablet) {
        grid-template-columns: 1fr 1fr;
    }

    .coup-de-coeur {
        display: grid;
        grid-template-rows: minmax(200px, 300px) 1fr auto;
    }

    .coup-de-coeur-bg {
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .coup-de-coeur-img {
        visibility: hidden;
        max-width: 100%;
    }

    .coup-de-coeur-desc {
        margin-top: 20px;

        p {
            font-size: 14px;
            line-height: 21px;
        }
    }
}

.marques {
    display: flex;
    flex-wrap: wrap;

    .marque {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: calc(33.3% - 10px);
        margin-bottom: 20px;
        margin-right: 10px;

        .marque-img {
            max-width: 100%;
        }
    }
}

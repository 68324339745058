@import "./mq";

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 48px;
  padding: 0 20px;

  font-family: var(--font-family-sans-serif-semibold);
  text-decoration: none;

  @include mq($from: tablet) {
    font-family: var(--font-family-sans-serif-bold);
    height: 56px;
}

  &.-pink {
    background-color: var(--color-pink);
    color: var(--color-white);

    &:hover {
      background-color: var(--color-pink-darker);
      transition: background var(--default-transition-params);
    }
  }

  &.-green {
    background-color: var(--color-green);
    color: var(--color-white);

    &:hover {
      background-color: var(--color-green-darker);
      transition: background var(--default-transition-params);
    }
  }
}

@import "../Assets/css/mq";

.carousel-wrapper {
  width: 100vw;

  .images-wrap {
    [aria-roledescription="slide"] {
      &:nth-child(even) {
        .each-slide {
          transform: translateY(20px);
          background-size: cover;
          background-position: bottom;
        }
        div {
          height: 570px;
        }
      }
    }
  }

  .each-slide {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      height: 550px;
    }
  }
}

@import "../Assets/css/mq";

.services-nav-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  list-style: none;
  margin: 0 0 40px;
  padding: 0;
  text-align: left;

  border-bottom: 2px solid var(--color-green);

  @include mq($from: tablet) {
    display: block;
    margin: 0 0 20px;
    border-bottom: none;
  }

  .item {
    margin-bottom: 0px;

    &:last-child {
      margin: 0;
    }

    @include mq($from: tablet) {
      margin-bottom: 20px;
    }
  }

  .link {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    font-family: var(--font-family-sans-serif-semibold);
    text-align: left;
    text-decoration: none;
    color: var(--color-primary);

    .title {
      display: inline-block;
      font-size: 4vw;
      text-transform: uppercase;
      padding: 6px;

      @include mq($from: tablet) {
        font-size: 36px;
      }
    }

    &:hover,
    &.active {
      .title {
        padding: 6px;
        background-color: var(--color-green);
        color: var(--color-white);
        transition: color var(--default-transition-params);

        @include mq($from: tablet) {
          background-color: inherit;
          color: var(--color-green);
        }
      }
    }
  }
}

.list-services-container {
  min-width: 100%;

  margin: 0 auto 40px;
  border-bottom: 2px solid var(--color-green);

  @include mq($from: tablet) {
    min-width: 380px;
    max-width: 380px;
  }

  ul {
    margin: 0 auto;
    padding: 0;
    list-style: none;
  }

  .list-sous-categories {
    .sous-categorie {
      margin-bottom: 30px;
    }

    .title-sous-categorie {
      margin: 0 0 15px;
      font-size: 24px;
      font-family: var(--font-family-sans-serif-regular);
    }

    .service {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      font-size: 16px;

      .name {
        max-width: 80%;
      }

      .description {
        text-align: right;
        font-family: var(--font-family-sans-serif-semibold);
      }

      .price {
        text-align: right;
        font-family: var(--font-family-sans-serif-semibold);
      }

      .plus {
        text-transform: none;
        font-style: normal;
      }
    }
  }
}

@import "../../Assets/css/mq";

.page-header-container {
    max-width: calc(var(--max-width) - 12vw);
    margin: 2vh auto;

    @include mq($from: tablet) {
        margin: 8vh auto;
    }

    .title {
        font-family: var(--font-family-sans-serif-semibold);
        font-size: 10vw;
        color: var(--color-green);
        letter-spacing: 10px;
        line-height: 1;

        @include mq($from: tablet) {
            font-size: 64px;
        }
    }
}

.background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;

    pointer-events: none;

    #canvas {
        width: 100%;
        height: 100%
    }
}

html,
body {
  margin: 0;
  font-family: var(--font-family-sans-serif-regular);
  line-height: 1.35;
  height: 100%;
  min-height: 100%;

  &.menuOpened {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
  }
}

#root {
  height: 100%;
  min-height: 100%;
}

.App {
  position: relative;
  z-index: 1;
}

h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

p {
  margin-top: 0;
  line-height: 26px;
}

.link {
  color: var(--color-green);
  transition: color var(--default-transition-params);

  &:hover {
    color: var(--color-green-darker);
  }
}
